




































































































































import Vue from "vue";
import Layout from "@/router/layouts/register.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { errorMessage } from "@/utils/messages-handlers";
import RegistrationStepper from "@/components/registration-stepper.vue";
Object.defineProperty(Vue.prototype, "$_", { value: _ });

export default Vue.extend({
  components: { Layout, RegistrationStepper },

  data() {
    return {
      arrowLeftBlue: require("@/assets/images/arrow-left-blue.svg"),
      arrowLeftWhite: require("@/assets/images/arrow-left-white.svg"),
      currentStep: 1,
      inputSearch: "",
      venueNotFound: false,
      geolocate: false,
      longtitude: "",
      latitude: "",
    };
  },

  mounted() {
    this.resetVenueData();
    this.setLocale();
    this.track();

    localStorage.removeItem("unfinishedRegistration");
  },

  computed: {
    ...mapGetters("registerModule", {
      getVenueRegisterData: "GET_FOUNDED_VENUE_DATA",
      isSearching: "GET_VENUES_SEARCHING",
    }),
  },

  methods: {
    ...mapActions("registerModule", {
      getVenue: "SET_GOOGLE_VENUE_DATA",
      getVenuePlaceId: "SET_VENUE_PLACE_ID",
      saveManuallyVenueData: "SET_MANUALLY_VENUE_DATA",
      resetVenueData: "RESET_VENUE_DATA",
      SetReferrerLink: "SET_REFERRER_LINK",
    }),
    async continueToStepTwo(index: string) {
      this.getVenuePlaceId(index);
    },
    continueToStepTwoManually() {
      this.saveManuallyVenueData(this.inputSearch);
    },
    assignCoordinates(position): void {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.searchVenues();
    },
    unassignCoordinates() {
      this.geolocate = false;
      this.latitude = "";
      this.longtitude = "";
      errorMessage(
        "Geolocation has been disabled for this site. Enable geolocation in your browser settings.",
        5000
      );
    },
    getLocation() {
      if (navigator.geolocation) {
        this.geolocate = true;
        navigator.geolocation.getCurrentPosition(
          this.assignCoordinates,
          this.unassignCoordinates
        );
      } else {
        this.geolocate = false;
        errorMessage("Geolocation is not supported by this browser.", 5000);
      }
    },
    setLocale() {
      if (this.$route.query.lang) {
        this.$i18n.locale = "sk";
        localStorage.setItem("language", "sk");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("language", "en");
      }
    },
    searchVenues: _.debounce(function () {
      const inputSearch: string = this.inputSearch;

      if (inputSearch.length) {
        let searchPayload = {};
        if (this.geolocate) {
          searchPayload = {
            name: inputSearch,
            geolocate: this.geolocate,
            latitude: this.latitude,
            longitude: this.longitude,
          };
        } else {
          searchPayload = {
            name: inputSearch,
            geolocate: this.geolocate,
          };
        }
        this.getVenue(searchPayload);
      }
    }, 500),
    track() {
      this.$gtag.event("registration", {
        event_category: "registration",
        event_label: "step",
        value: "1",
      });
    },
  },
  watch: {
    getVenueRegisterData(value) {
      this.venueNotFound = false;
      if (this.inputSearch.length) {
        if (value.length <= 0) {
          this.venueNotFound = true;
        }
      }
    },
    inputSearch(value) {
      if (!value.length) {
        this.venueNotFound = false;
      }
    },
    geolocate(value) {
      if (value) {
        this.getLocation();
      } else {
        this.searchVenues();
      }
    },
  },
});
